var api = require("!../../../../.yarn/__virtual__/style-loader-virtual-95b4ca68ce/0/cache/style-loader-npm-1.2.1-d2e298addc-cd30484665.zip/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../../../../.yarn/__virtual__/css-loader-virtual-2ae40ad6f9/0/cache/css-loader-npm-3.5.3-0f886851e6-e5fa1707d7.zip/node_modules/css-loader/dist/cjs.js??ref--8-1!../../../../.yarn/cache/resolve-url-loader-npm-3.1.2-38e8927e94-02e559af8d.zip/node_modules/resolve-url-loader/index.js!../../../../.yarn/__virtual__/sass-loader-virtual-9829a867a7/0/cache/sass-loader-npm-8.0.2-f0d209ad64-3e9ba97432.zip/node_modules/sass-loader/dist/cjs.js!./pagination.scss");

            content = content.__esModule ? content.default : content;

            if (typeof content === 'string') {
              content = [[module.id, content, '']];
            }

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



module.exports = content.locals || {};